@use 'variables' as *;

.button {
  background: $secondary-color;
  color: white;
  border: 1px solid $secondary-color;
  width: calculate-rem(300px);

  &:active, &:hover {
    color: #f5f6fa;
    background-color: rgb(131 185 201);
    border: 1px solid rgb(131 185 201);
  }

  &:disabled {
    color: white;
    background-color: rgb(80 173 201 / 45%);
    border: 1px solid rgb(80 173 201 / 45%);
  }

  line-height: calculate-rem(22px);
  border-radius: calculate-rem(4px);
  padding: 10px 40px;
  font-size: calculate-rem(18px);
  box-sizing: border-box;

  display: flex;
  align-items: center;

  mat-icon {
    margin-right: calculate-rem(10px);
  }

  &:active,
  &:focus {
    outline: 0;
  }
}


@media (max-width: $sm) {
  .button {

    width: calculate-rem(225px);
    font-size: calculate-rem(14px);

    mat-icon {
      font-size: calculate-rem(18px);
      height: calculate-rem(18px);
      width: calculate-rem(18px);
    }
  }
}