@use "sass:math";

$base-font-size: 16px;

//breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$primary-color: #242943;
$secondary-color: #50ADC9;
$accent-color: #D9D9D9;


$primary-hover-color: #3a3f54;
$secondary-hover-color: #83B9C9;
$accent-hover-color: #bfbfbf;

$delete-color: #F44336;
$delete-hover-color: #fc5145;


$esk: #AC112B;
$kgyk: #357ABE;
$mek: #D67C1C;
$dfk: #EEC344;
$mk: #7765A6;
$ak: #2A8C46;
$givk: #A1C038;
$eekk: #AE791D;
$ahjk: #B7B2AC;

$category-actions-width: 100px;

@function calculate-rem($size) {
  $rem-size: math.div($size, $base-font-size);
  @return #{$rem-size}rem;
}

@font-face {
  font-family: Montserrat;
  src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat-bold;
  src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat-medium;
  src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat-italic;
  src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
}

$header-height: 60px;