.loading-icon {
  padding-bottom: 30px;
  width: 100px;

  fill: transparent;
  stroke: #005a93;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: 125.5;
  transform-origin: 20px 20px 0;
  animation: main-spinner 2s linear infinite;
}

.main-spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
}

.main-spinner-container img {
  width: 250px;
}

svg.main-spinner {
  width: 40px;
  height: 40px;
  transform-origin: top left;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main-spinner {
    display: none;
  }
}

@keyframes main-spinner {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}
